import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-otp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VOtpInput = _resolveComponent("VOtpInput")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["otp-input flex flex-row mb-2", 'otp-input-size-' + _ctx.size])
    }, [
      _createVNode(_component_VOtpInput, {
        ref: "inputOtpRef",
        "num-inputs": _ctx.size,
        separator: "",
        "is-input-num": "",
        "should-auto-focus": "",
        "input-classes": "text-center otp-input__box",
        onOnChange: _ctx.handleOnChange,
        onOnComplete: _ctx.handleOnComplete
      }, null, 8, ["num-inputs", "onOnChange", "onOnComplete"])
    ], 2),
    _createVNode(_component_a_form_item, {
      ref: "formItemForValidationRef",
      value: _ctx.localValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      name: _ctx.name,
      rules: 
        _ctx.concat(_ctx.$attrs.rules || [], [
          _ctx.makeRequiredMinimumRule(
            _ctx.size,
            _ctx.t('Your code should be {size} characters long.', {
              size: _ctx.size,
            })
          ),
        ])
      ,
      class: "hidden-input-otp",
      "validate-first": true,
      "validate-trigger": _ctx.$attrs.validateTrigger
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_input, { class: "p-0 border-none h-0" })
      ]),
      _: 1
    }, 8, ["value", "name", "rules", "validate-trigger"])
  ]))
}