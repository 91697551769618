import gql from "graphql-tag";

export const setUp2faGql = gql`
  mutation SetUp2fa($input: SetUp2FAInput!) {
    setUp2fa(input: $input) {
      ... on User {
        id
        username
        name
        avatar40
        avatar80
        avatar400
        pendingAction
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
