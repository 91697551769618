import gql from "graphql-tag";

export const generate2faGql = gql`
  mutation Generate2fa {
    generate2fa {
      ... on OTPDevice {
        isEnabled
        secretKey
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
