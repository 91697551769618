import gql from "graphql-tag";

export const revoke2faGql = gql`
  mutation Revoke2fa {
    revoke2fa {
      ... on User {
        id
        username
        created
        wallet {
          id
          stakingKeyHash
        }
        name
        avatar40
        avatar80
        avatar400
        pendingAction
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
