import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { generate2faGql } from "@/api/twoFactorAuthentication/generate2fa";
import { setUp2faGql } from "@/api/twoFactorAuthentication/setUp2fa";
import {
  Generate2fa,
  Generate2fa_generate2fa_OTPDevice,
} from "@/api/twoFactorAuthentication/__generated__/Generate2fa";
import {
  SetUp2fa,
  SetUp2faVariables,
  SetUp2fa_setUp2fa_User,
} from "@/api/twoFactorAuthentication/__generated__/SetUp2fa";
import { computed, Ref, ref } from "vue";
import { useStore } from "vuex";
import isEmpty from "lodash/isEmpty";
import { createQrCodeUri } from "@/shared/utils/2faUtils";
import { apiErrorCodes } from "../utils/constants";
import { parseGqlResponse } from "../utils/graphql/responseParser";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import InputOtp from "@/shared/components/Forms/InputOtp.vue";
import { revoke2faGql } from "@/api/twoFactorAuthentication/revoke2fa";
import {
  Revoke2fa,
  Revoke2fa_revoke2fa_User,
} from "@/api/twoFactorAuthentication/__generated__/Revoke2fa";

/**
 * Reusable composable for 2fa setup
 *
 * @param otpInputRef - the otp input ref, this is used for the clearInput method
 * @returns
 */
export function use2FASetup(otpInputRef?: Ref<typeof InputOtp>) {
  const store = useStore();
  const validated = ref(false);
  const secretKey = ref("");

  /**
   * GENERATE 2FA KEY
   */
  const generate2faMutation = useCustomMutation<Generate2fa>(generate2faGql);

  const handleGenerate2fa = async () => {
    const generate2faResponse = await generate2faMutation.mutate();
    const parsedResponse = parseGqlResponse<Generate2fa_generate2fa_OTPDevice>(
      "OTPDevice",
      generate2faResponse,
      apiErrorCodes.INTERNAL_ERROR
    );

    console.log("handleGenerate2fa:parsedResponse", parsedResponse);
    if (parsedResponse.data?.secretKey) {
      secretKey.value = parsedResponse.data?.secretKey;
    }
  };
  /**
   * END GENERATE 2FA KEY
   */

  /**
   * VALIDATE 2FA
   */
  const setup2faMutation = useCustomMutation<SetUp2fa, SetUp2faVariables>(
    setUp2faGql
  );

  const handle2faValidate = async (otpCode) => {
    const setUp2faResponse = await setup2faMutation.mutate({
      input: {
        otpCode,
      },
    });

    const parsedResponse = parseGqlResponse<SetUp2fa_setUp2fa_User>(
      "User",
      setUp2faResponse,
      apiErrorCodes.INTERNAL_ERROR
    );
    console.log("handle2faValidate:parsedResponse", parsedResponse);

    /**
     * There is no confirmation from backend if 2fa is valid
     * Assume that it's valid when the user is returned
     */
    if (parsedResponse.data?.id) {
      await store.dispatch("userUpdate", {
        pendingAction: parsedResponse.data?.pendingAction,
      });

      validated.value = true;

      await store.dispatch("authUpdate", {
        isAuthenticated: true,
      });

      // also save to register store
      registrationSimpleStore.pendingAction =
        parsedResponse.data?.pendingAction;

      /**
       * send back success, although this is not required since validated value will
       * also be available and returned data here will be save at this point
       */
      return true;
    } else {
      /**
       * TODO: Handle other error
       */
      otpInputRef?.value?.clearInput();
    }

    // send false if invalid otp code
    return false;
  };
  /**
   * END VALIDATE 2FA
   */

  /**
   * REVOKE 2FA
   */
  const revoke2faMutation = useCustomMutation<Revoke2fa>(revoke2faGql);
  const handle2faRevoke = async () => {
    const revokeResponse = await revoke2faMutation.mutate();

    const parsedResponse = parseGqlResponse<Revoke2fa_revoke2fa_User>(
      "User",
      revokeResponse,
      apiErrorCodes.INTERNAL_ERROR
    );

    console.log("parsedResponse", parsedResponse);

    const user = parsedResponse?.data;

    /**
     * Properly throw error when there's an error from endpoint
     */
    if (!isEmpty(parsedResponse.error?.errors) || !user?.id) {
      /**
       * TODO: Send error to logging endpoint
       */
      throw new Error("Failed to revoke 2fa");
    }

    if (user?.id) {
      await store.dispatch("userUpdate", user);
    }
  };
  /**
   * END REVOKE 2FA
   */

  return {
    validated,
    otpSetupDetails: computed(() => {
      if (generate2faMutation.loading.value) {
        return {};
      }

      const username = store.state?.userInfo?.name || "";
      return {
        username,
        secret: secretKey.value,
        uri: createQrCodeUri(secretKey.value, username || "User"),
      };
    }),
    secretKey,
    handleGenerate2fa,
    generate2faLoading: computed(() => generate2faMutation.loading.value),
    handle2faValidate,
    setup2faLoading: computed(() => setup2faMutation.loading.value),
    handle2faRevoke,
    revoke2faLoading: computed(() => revoke2faMutation.loading.value),
  };
}
