import { config } from "@/shared/utils/config";

/**
 * Create a QR code uri
 *
 * @param secretKey - TOTP secret key
 * @param user - User to be shown on Authenticator App
 * @returns
 */
export const createQrCodeUri = (secretKey: string, user = "") => {
  return `otpauth://totp/${config.merchantBrand}:${user}?secret=${secretKey}&issuer=${config.merchantBrand}`;
};
