
import { defineComponent, PropType, ref } from "vue";
import { useVModel } from "vue-composable";
import { useI18n } from "vue-i18n";
import VOtpInput from "vue3-otp-input";
import concat from "lodash/concat";
import { makeRequiredMinimumRule } from "@/shared/utils/validators/commonValidators";

export type OTPInputLength = 4 | 5 | 6;

export default defineComponent({
  components: { VOtpInput },
  props: {
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    size: {
      type: Number as PropType<OTPInputLength>,
      default: 6,
    },
  },
  emits: ["update:value", "on-complete"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const inputOtpRef = ref();
    const formItemForValidationRef = ref();
    const localValue = useVModel(props, "value");

    const handleValidation = (value) => {
      /**
       * Handle validation manually, ant form doesn't trigger
       * change validation, use the makeRequiredMinimumRule.asyncValidator
       * method then clearValidate is valid value
       */
      if (value && formItemForValidationRef.value.clearValidate) {
        makeRequiredMinimumRule(props.size, "")
          .asyncValidator({}, value)
          .then(formItemForValidationRef.value.clearValidate)
          .catch(() => {});
      }
    };

    const handleOnChange = (value) => {
      emit("update:value", value);
      handleValidation(value);
    };

    const handleOnComplete = (value) => {
      emit("update:value", value);
      handleValidation(value);

      emit("on-complete", value);
    };

    return {
      t,
      inputOtpRef,
      formItemForValidationRef,
      localValue,
      handleOnChange,
      clearInput: () => {
        /**
         * This exposes vue3-otp-input clearInput method
         * It is necessary to be returned in this setup, so it can be used as ref.clearInput()
         */
        inputOtpRef.value?.clearInput();
      },
      handleOnComplete,
    };
  },
  methods: {
    makeRequiredMinimumRule,
    concat,
  },
});
